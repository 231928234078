import axios, { BASE_URL, DELETE, GET, PATCH, POST } from "../utils/axiosClient"
import {
    formatRequestData,
    formatResponseData,
    formatUrl,
} from "../utils/jsonApiFormatters"

export const getEntityService = async (
    entity: string,
    params: string,
    isJSONAPI = true,
    apiUrl
) => {
    try {
        const PATH = `/${entity}`
        const url = formatUrl(PATH, params)
        const response = await axios(GET, url, null, isJSONAPI, apiUrl)
        if (!isJSONAPI) {
            return response.data
        } else {
            const { data } = formatResponseData(response.data)
            return data
        }
    } catch (error) {
        throw error
    }
}
interface IGetEntityServiceParams {
    include: string
}
export const getEntityByIdService = async (
    entity: string,
    id: number,
    params?: IGetEntityServiceParams
) => {
    try {
        const PATH = `/${entity}/${id}`
        const url = formatUrl(PATH, params)
        const response = await axios(GET, url)
        const { data } = formatResponseData(response.data)
        return data
    } catch (error) {
        throw error
    }
}

export const createEntityService = async (
    entity: string,
    formData: any,
    apiUrl: string = BASE_URL,
    isJSONAPI = true
) => {
    try {
        const preparedData = isJSONAPI
            ? formatRequestData(entity, formData)
            : formData
        const response = await axios(
            POST,
            `/${entity}`,
            preparedData,
            isJSONAPI,
            apiUrl
        )
        if (!isJSONAPI) {
            return response.data
        } else {
            const { data, meta } = formatResponseData(response.data)
            return { data, meta }
        }
    } catch (error) {
        throw error
    }
}

export const editEntityService = async (
    entity: string,
    id: number,
    formData: any,
    apiUrl: string = BASE_URL
) => {
    try {
        const preparedData = formatRequestData(entity, formData)
        const response = await axios(
            PATCH,
            `/${entity}/${id}`,
            preparedData,
            true,
            apiUrl
        )
        const { data, meta } = formatResponseData(response.data)
        return { data, meta }
    } catch (error) {
        throw error
    }
}

export const deleteEntityService = async (entity: string, id: number) => {
    try {
        await axios(DELETE, `/${entity}/${id}`)
    } catch (error) {
        throw error
    }
}

export const deleteAllEntityService = async (
    entity: string,
    params: string
) => {
    try {
        const url = formatUrl(`/${entity}`, params)
        await axios(POST, url)
    } catch (error) {
        throw error
    }
}
