import { useState, useRef, useEffect, useContext } from "react"
import { Outlet } from "react-router-dom"

import { CurrentUserContext } from "context/CurrentUserContext"

import useWindowDimensions from "hooks/useWindowDimension"

import useMenuItems from "components/Sidebar/useMenuItems"
import Header from "components/Header"
import { Sidebar } from "components/Sidebar"
import { MEDIUM_SCREEN_WIDTH } from "constants/enums"

const Layout = ({ children }: any) => {
    const { currentUser } = useContext(CurrentUserContext)
    const menuItems = useMenuItems(currentUser?.role)

    const [openSidebar, setOpenSidebar] = useState(false)

    const hocRef = useRef(null)
    const hamBtnRef = useRef(null)

    const { width } = useWindowDimensions()

    const handleClickOutside = (event: any) => {
        if (document.body.clientWidth <= MEDIUM_SCREEN_WIDTH) {
            if (
                hocRef &&
                hocRef.current &&
                !hocRef.current.contains(event.target) &&
                !hamBtnRef.current.contains(event.target)
            ) {
                setOpenSidebar(false)
            } else if (
                openSidebar &&
                hamBtnRef.current.contains(event.target)
            ) {
                setOpenSidebar(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <div>
            <Sidebar
                menuItems={menuItems}
                openSidebar={openSidebar}
                setOpenSidebar={setOpenSidebar}
                hocRef={hocRef}
                width={width}
            />
            <div className={`m-main ${openSidebar ? "-sidebarIsOpen" : ""}`}>
                <Header
                    setOpenSidebar={setOpenSidebar}
                    openSidebar={openSidebar}
                    hamBtnRef={hamBtnRef}
                />

                <div className="m-main__wrapper">
                    <div className="_wr ">
                        <div className="_w">
                            <div className="m-main__content">
                                {children ? children : <Outlet />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout
