import axios, { AxiosResponse } from "axios"
import {
    deleteUserId,
    deleteUserToken,
    getUserToken,
    clearStorage,
} from "../services/localStorage.service"

export const BASE_URL = process.env.REACT_APP_API_HOST
export const BASE_FILE_URL = process.env.REACT_APP_FILE_URL
export const STATISTIC_URL = process.env.REACT_APP_STATISTIC_URL
export const SCHEDULER_URL = process.env.REACT_APP_SCHEDULER_URL
export const TRANSLATION_URL = process.env.REACT_APP_TRANSLATOR_URL

export const GET = "get"
export const POST = "post"
export const PUT = "put"
export const PATCH = "patch"
export const DELETE = "delete"

const axiosClient = (
    action: string,
    path?: string,
    data?: any,
    isJSONAPi = true,
    url = BASE_URL,
    responseType = "json"
): Promise<AxiosResponse<any, any>> => {
    const token = getUserToken()
    const options = {
        headers: {
            "Content-Type": isJSONAPi
                ? "application/vnd.api+json"
                : "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    }
    if (token) {
        options.headers["Authorization"] = `Bearer ${token}`
    }

    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 409
            ) {
                deleteUserId()
                deleteUserToken()
            }
            return Promise.reject(error)
        }
    )

    if (action === GET || action === DELETE) {
        return axios[action](`${url}${path}`, options)
    }

    return axios[action](`${url}${path}`, data, { ...options, responseType })
}

export default axiosClient
