import { Navigate, Route, Routes } from "react-router-dom"

import loadable from "utils/loadable"

import ROUTES from "constants/routes"

const Login = loadable(() => import("screens/NoAuth/Login"))
const ForgotPassword = loadable(() => import("screens/NoAuth/ForgotPassword"))
const ResetPassword = loadable(() => import("screens/NoAuth/ResetPassword"))

const NoAuthRoutes = () => {
    return (
        <Routes>
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route
                path={`${ROUTES.RESET_PASSWORD}/:token`}
                element={<ResetPassword />}
            />
            <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Routes>
    )
}

export default NoAuthRoutes
