import React, { useState, useEffect, FC } from "react"

import { ALERT_POSITIONS, ALERT_TYPES } from "constants/enums"
import { IAlert } from "components/Alert/IAlert"
import { IContextProps } from "utils/IContextProps"

const defaultValue: IAlert = {
    alert: "",
    type: "",
    position: "",
}

export const AlertContext = React.createContext(defaultValue)

const ALERT_DURATION = 2000

const AlertContextProvider: FC<IContextProps> = ({ children }) => {
    const [alert, setAlert] = useState("")
    const [type, setType] = useState(ALERT_TYPES.DEFAULT)
    const [position, setPosition] = useState(ALERT_POSITIONS.CENTER)

    useEffect(() => {
        const timer = setTimeout(() => setAlert(""), ALERT_DURATION)
        return () => {
            clearTimeout(timer)
        }
    }, [alert])

    const handleSetAlert = (
        data,
        alertType = ALERT_TYPES.DEFAULT,
        alertPosition = ALERT_POSITIONS.LEFT
    ) => {
        switch (alertType) {
            case ALERT_TYPES.SUCCESS:
                setAlert(data)
                break
            case ALERT_TYPES.ERROR:
                if (data.response) {
                    setAlert(
                        data.response?.data?.message ||
                            data?.response?.data?.detail
                    )
                } else if (data.message) {
                    setAlert(data.message)
                } else {
                    setAlert(data)
                }
                break
            case ALERT_TYPES.DEFAULT:
                setAlert(data)
                break
            default:
                setAlert("")
                break
        }
        setType(alertType)
        setPosition(alertPosition)
    }

    return (
        <AlertContext.Provider
            value={{
                alert,
                type,
                position,
                setAlert: handleSetAlert,
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}

export default AlertContextProvider
