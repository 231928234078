import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { IAvatarDropdown } from "./IAvatarDropDown"
import { Avatar } from "../../Avatar"
import clickOutsideHOC from "components/wrappers/clickOutsideHOC"
import { CurrentUserContext } from "context/CurrentUserContext"
import { clearStorage } from "services/localStorage.service"
import ROUTES from "constants/routes"
import { ELEMENT_SIZE, ICON_SIZE } from "constants/enums"
import { BASE_FILE_URL } from "utils/axiosClient"
import { Icon } from "components/Icons"
import { useTranslate } from "react-polyglot"

function AvatarDropdown({ open }: IAvatarDropdown) {
    const t = useTranslate()
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

    const handleLogout = () => {
        setCurrentUser(null)
        clearStorage()
    }

    const { firstName, lastName, avatarPath, role } = currentUser || {}

    return (
        <div className={`a-avatarBox`}>
            <Avatar
                role={role && role.name}
                size={ELEMENT_SIZE.MEDIUM}
                avatarPath={avatarPath ? `${BASE_FILE_URL}${avatarPath}` : ""}
                firstName={firstName}
                lastName={lastName}
                outline
            />
            <div>
                <i className={`a-chevron ${open ? "-up" : "-down"}`} />
                <div className={`m-avatar__popup ${open ? "-open" : ""}`}>
                    <Link
                        className="m-avatar__item -profile"
                        to={ROUTES.USER_PROFILE}
                    >
                        <div className="a-account">
                            <Icon size={ICON_SIZE.MEDIUM} name="user" />
                            <span>{t("button.account")}</span>
                        </div>
                    </Link>
                    <Link
                        className="m-avatar__item -logout"
                        to={ROUTES.HOME}
                        onClick={handleLogout}
                    >
                        {t("button.logOut")}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default clickOutsideHOC(AvatarDropdown)
