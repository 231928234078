import React, { useContext } from "react"
import { useTranslate } from "react-polyglot"
import { ALERT_TYPES } from "../../constants/enums"
import { AlertContext } from "../../context/AlertContext"
import { Icon } from "../Icons"

const Alert = () => {
    const { alert, type, position, setAlert } = useContext(AlertContext)
    const t = useTranslate()

    if (!alert) return null

    return (
        <span
            className={`a-alert -${type} -${position}`}
            onClick={() => setAlert("")}
        >
            {type === ALERT_TYPES.DEFAULT && (
                <Icon name="bell" color="#fff" size={24} />
            )}
            {t(alert)}
        </span>
    )
}

export default Alert
