const USER_TOKEN = "g-counter_user_token"
const USER_ID = "g-counter_user_id"
const USER_LANGUAGE = "g-counter_user_language_code"

export const getUserToken = () => {
    return localStorage.getItem(USER_TOKEN)
}

export const setUserToken = (token: string) => {
    localStorage.setItem(USER_TOKEN, token)
}

export const deleteUserToken = () => {
    localStorage.removeItem(USER_TOKEN)
}

export const getUserId = () => {
    return Number(localStorage.getItem(USER_ID))
}

export const setUserId = (id: string) => {
    localStorage.setItem(USER_ID, id)
}

export const deleteUserId = () => {
    localStorage.removeItem(USER_ID)
}

export const getUserLanguage = () => {
    return localStorage.getItem(USER_LANGUAGE)
}

export const setUserLanguage = (code: string) => {
    localStorage.setItem(USER_LANGUAGE, code)
}

export const clearStorage = () => {
    deleteUserToken()
    deleteUserId()
}
