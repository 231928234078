import { useContext, useEffect, useState } from "react"

import { AlertContext } from "context/AlertContext"

import axios, { BASE_URL, GET } from "../utils/axiosClient"
import { formatResponseData, formatUrl } from "../utils/jsonApiFormatters"

import { IFetchPaginated, IResponse, Params } from "./ICustomHook"

import { ALERT_TYPES } from "constants/enums"

const useFetchData = (
    entity: string,
    params: Params = {},
    reload: any = null,
    isJSONAPI: boolean = true,
    condition: boolean = true,
    apiUrl: string = BASE_URL
): IFetchPaginated => {
    const [response, setResponse] = useState<IResponse>({
        data: [],
        meta: null,
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)

    const { setAlert } = useContext(AlertContext)

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                let PATH = `/${entity}`
                const url = formatUrl(PATH, params)
                const response = await axios(GET, url, null, isJSONAPI, apiUrl)
                if (!isJSONAPI) {
                    setResponse(response.data)
                } else {
                    const { data, meta } = formatResponseData(response.data)
                    setResponse({ data, meta })
                }
                setIsLoading(false)
                setError(null)
            } catch (error) {
                setResponse({ data: [], meta: null })
                setIsLoading(false)
                setError(error)
                if (error?.response?.status === 403) {
                    setAlert(error, ALERT_TYPES.ERROR)
                }
            }
        }

        if (entity && condition) {
            getData()
        }
    }, [reload])

    return {
        response,
        isLoading,
        error,
    }
}

export default useFetchData
