import { ROLES } from "constants/enums"
import ROUTES from "constants/routes"

const LOCATION_MANAGER_MENU_ITEMS = [
    {
        itemKey: "dashboard",
        label: "menuItems.dashboard",
        to: ROUTES.HOME,
        icon: "dashboard",
    },
    {
        itemKey: "reports",
        label: "menuItems.reports",
        to: `${ROUTES.REPORTS}${ROUTES.DAILY}`,
        icon: "reports",
        subitems: [
            {
                itemKey: "daily-reports",
                label: "menuItems.dailyReport",
                to: `${ROUTES.REPORTS}${ROUTES.DAILY}`,
                icon: "box",
            },
            {
                itemKey: "weekly-reports",
                label: "menuItems.weeklyReport",
                to: `${ROUTES.REPORTS}${ROUTES.WEEKLY}`,
                icon: "box",
            },
            {
                itemKey: "monthly-reports",
                label: "menuItems.monthlyReport",
                to: `${ROUTES.REPORTS}${ROUTES.MONTHLY}`,
                icon: "box",
            },
            {
                itemKey: "periodical-reports",
                label: "menuItems.periodicalReport",
                to: `${ROUTES.REPORTS}${ROUTES.PERIODICAL}`,
                icon: "box",
            },
            {
                itemKey: "yearly-reports",
                label: "menuItems.yearlyReport",
                to: `${ROUTES.REPORTS}${ROUTES.YEARLY}`,
                icon: "box",
            },
        ],
    },
]

const REGIONAL_MANAGER_MENU_ITEMS = [
    ...LOCATION_MANAGER_MENU_ITEMS,
    {
        divider: true,
    },
    {
        itemKey: "compare-locations",
        label: "menuItems.compareLocations",
        to: ROUTES.COMPARE_LOCATIONS,
        icon: "compare",
    },
]

const COMPANY_MANAGER_MENU_ITEMS = [
    ...REGIONAL_MANAGER_MENU_ITEMS,
    {
        divider: true,
    },
    {
        itemKey: "region-management",
        label: "menuItems.regionManagement",
        to: ROUTES.REGION_MANAGEMENT,
        icon: "location-route",
    },
    {
        itemKey: "location-management",
        label: "menuItems.locationManagement",
        to: ROUTES.LOCATION_MANAGEMENT,
        icon: "map-pin",
    },
    {
        itemKey: "working-time-management",
        label: "menuItems.workingTimeManagement",
        to: ROUTES.WORKING_TIME_MANAGEMENT,
        icon: "workingTime",
    },
    {
        itemKey: "mailing-report-management",
        label: "menuItems.mailingReports",
        to: ROUTES.MAILING_REPORTS,
        icon: "mailing-report",
    },
]

const ADMIN_MENU_ITEMS = [
    ...COMPANY_MANAGER_MENU_ITEMS,
    {
        divider: true,
    },
    {
        itemKey: "company-management",
        label: "menuItems.companyManagement",
        to: ROUTES.COMPANY_MANAGEMENT,
        icon: "company",
    },
    {
        itemKey: "countries-management",
        label: "menuItems.countriesManagement",
        to: ROUTES.COUNTRY_MANAGEMENT,
        icon: "country",
    },
    {
        itemKey: "city-management",
        label: "menuItems.cityManagement",
        to: ROUTES.CITY_MANAGEMENT,
        icon: "city",
    },
    {
        itemKey: "gate-management",
        label: "menuItems.gateManagement",
        to: ROUTES.GATE_MANAGEMENT,
        icon: "gate",
    },
    {
        itemKey: "camera-management",
        label: "menuItems.cameraManagement",
        to: ROUTES.CAMERA_MANAGEMENT,
        icon: "camera",
    },
    {
        itemKey: "user-management",
        label: "menuItems.userManagement",
        to: ROUTES.USER_MANAGEMENT,
        icon: "people",
    },
    {
        itemKey: "translation-management",
        label: "menuItems.translationManagement",
        to: ROUTES.TRANSLATION_MANAGEMENT,
        icon: "translate",
    },
    {
        itemKey: "visit-management",
        label: "menuItems.visitManagement",
        to: ROUTES.VISIT_MANAGEMENT,
        icon: "visit",
    },
]

const useMenuItems = (userRole) => {
    switch (userRole.id) {
        case ROLES.ROLE_ADMIN.id:
            let adminMenuItems = [...ADMIN_MENU_ITEMS]
            const adminDashboard = adminMenuItems.findIndex(
                (item) => item["itemKey"] === "dashboard"
            )
            adminMenuItems[adminDashboard]["to"] = ROUTES.ADMIN_DASHBOARD
            return adminMenuItems
        case ROLES.ROLE_COMPANY_MANAGER.id:
            return COMPANY_MANAGER_MENU_ITEMS
        case ROLES.ROLE_REGIONAL_MANAGER.id:
            return REGIONAL_MANAGER_MENU_ITEMS
        case ROLES.ROLE_LOCATION_MANAGER.id:
            return LOCATION_MANAGER_MENU_ITEMS
        default:
            return []
    }
}

export default useMenuItems
