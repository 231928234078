export default {
    USER: "users",
    COUNTRY: "countries",
    FILE: "files",
    SYS_FILE: "/sys_files",
    COMPANY: "companies",
    ROLE: "roles",
    LOGO: "logo",
    LOCATION: "locations",
    IMAGE: "images",
    CITY: "cities",
    DAY: "days",
    WORKING_PERIOD: "working_periods",
    WORKING_TIME: "working_times",
    REGION: "regions",
    GATE: "gates",
    CAMERA: "cameras",
    COMPARED_LOCATIONS: "compared_locations",
    STATISTIC_REPORTS: "statistic_reports",
    SCHEDULER: "schedulers",
    EMAIL_SCHEDULER: "email_schedulers",
    SCHEDULER_REPORT_TYPES: "scheduler_report_types",
    TRANSLATION: "translations",
    LANGUAGE: "languages",
    GENERAL_STATISTIC: "general_statistic",
    LOCATION_STATISTIC: "location_statistic",
    TOP_LOCATIONS: "top_locations",
    STATISTICS: "statistics",
    VISITS: "visits",
    CAMERA_STATISTIC: "sync_cameras_data",
    MODEL: "camera_models",
}
