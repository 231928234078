import { Fragment, useContext } from "react"
import { I18n } from "react-polyglot"
import { BrowserRouter as Router } from "react-router-dom"

import { TranslationContext } from "context/TranslationContext"

import { getUserToken } from "services/localStorage.service"

import AuthRoutes from "routes/AuthRoutes"
import NoAuthRoutes from "routes/NoAuthRoutes"

import Alert from "components/Alert"

function App() {
    const token = getUserToken()

    const {
        translations: { locale, messages },
    } = useContext(TranslationContext)

    return (
        <Fragment>
            <I18n locale={locale} messages={messages}>
                <Router>
                    <Alert />
                    {token ? <AuthRoutes /> : <NoAuthRoutes />}
                </Router>
            </I18n>
        </Fragment>
    )
}

export default App
