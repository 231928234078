import React, { ChangeEvent, useContext, useState } from "react"

import { ELEMENT_SIZE, ICON_SIZE, INPUT_FILED_TYPE } from "constants/enums"

import Input from "components/Input"
import AvatarDropdown from "./AvatarDropDown"
import { Icon } from "../Icons"
import LanguagePicker from "components/LanguagePicker"

const Header = ({ setOpenSidebar, openSidebar, hamBtnRef }) => {
    const [searchValue, setSearchValue] = useState("")

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setSearchValue(value)
    }

    return (
        <header>
            <div
                className="m-toggler"
                ref={hamBtnRef}
                onClick={() => setOpenSidebar(!openSidebar)}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="m-headerMain">
                <div className="m-headerMain__search">
                    {/* <Input
                        name="globalSearch"
                        type={INPUT_FILED_TYPE.TEXT}
                        placeholder="form.placeholder.search"
                        value={searchValue}
                        onChange={handleSearch}
                        icon="search"
                        iconColor="#5138ee"
                    /> */}
                </div>
                <div className="m-headerMain__icons">
                    {/* <div className="m-headerMain__icons--wrapper">
                        <Icon
                            color="#071A53"
                            size={ICON_SIZE.MEDIUM}
                            name="email"
                        />
                    </div>
                    <div className="m-headerMain__icons--wrapper">
                        <Icon
                            color="#071A53"
                            size={ICON_SIZE.MEDIUM}
                            name="bell"
                        />
                    </div> */}

                    <div className="m-headerMain__icons--wrapper">
                        <LanguagePicker />
                    </div>
                    <div className="m-headerMain__icons--wrapper">
                        <AvatarDropdown
                            size={ELEMENT_SIZE.SMALL}
                            open
                            outline
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
