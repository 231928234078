import React, { FC, useEffect, useState } from "react"

import { getUserId } from "services/localStorage.service"

import useFetchDataById from "hooks/useFetchDataById"

import { IContextProps } from "utils/IContextProps"

import ENTITY from "constants/entities"

import { IUser } from "models/IUser"
import { ICurrentUserContext } from "./IContext"

const defaultState = {
    currentUser: null,
    setCurrentUser: () => {},
    userId: -1,
    userCompanyId: null,
    isLoading: false,
    reloadCurrentUser: () => {}
}

export const CurrentUserContext =
    React.createContext<ICurrentUserContext>(defaultState)

const CurrentUserContextProvider: FC<IContextProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<IUser | null>(null)
    const [userCompanyId, setUserCompanyId] = useState<number | null>(null)
    const userId = getUserId()
    const [reload, setReload] = useState(false)

    const { data, isLoading } = useFetchDataById(ENTITY.USER, userId, {
        include: "role,avatar,company,locations",
    }, reload)

    useEffect(() => {
        setCurrentUser(data)
        if(data){
            setUserCompanyId(data?.company?.id)
        }
        // data?.company && setUserCompanyId(data.company.id)
    }, [data])

    const reloadCurrentUser = () => {
        setReload(!reload)
    }

    return (
        <CurrentUserContext.Provider
            value={{
                currentUser,
                isLoading,
                setCurrentUser,
                userId,
                userCompanyId,
                reloadCurrentUser
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    )
}

export default CurrentUserContextProvider
