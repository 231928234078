import React, { lazy, Suspense } from "react"

const loadable = (importFunction: any) => {
    const LazyComponent = lazy(importFunction)
    return (props: any) => (
        <Suspense fallback={null}>
            <LazyComponent {...props} />
        </Suspense>
    )
}

export default loadable
