import React, { useContext, useRef } from "react"

import { TranslationContext } from "context/TranslationContext"

import useFetchData from "hooks/useFetchData"

import clickOutsideHOC from "components/wrappers/clickOutsideHOC"

import ENTITY from "constants/entities"
import { TRANSLATION_URL } from "utils/axiosClient"

const LanguagePicker = ({ open, setOpen }) => {
    const hocRef = useRef(null)

    const { translations, setTranslations } = useContext(TranslationContext)

    const {
        response: { data },
        isLoading,
    } = useFetchData(
        ENTITY.LANGUAGE,
        { pagination: false },
        false,
        true,
        true,
        TRANSLATION_URL
    )

    if (!data) return null

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleSelect = (language) => {
        setTranslations(language)
        setOpen(false)
    }

    return (
        <div className="m-selectGroup -languagePicker">
            <div className="m-selectGroup__container" ref={hocRef}>
                <input
                    value={translations.locale}
                    className={`m-selectGroup__select ${open ? "-open" : ""}`}
                    readOnly
                />
                <span className="a-chevron -down" onClick={handleOpen}>
                    {""}
                </span>
                <div
                    className={`m-selectGroup__options ${open ? "-open" : ""}`}
                >
                    <ul>
                        {data.map((item, index) => (
                            <li key={index} onClick={() => handleSelect(item)}>
                                {item.code}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default clickOutsideHOC(LanguagePicker)
