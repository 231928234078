export const MEDIUM_SCREEN_WIDTH = 768

export const FILE_TYPES = {
    IMAGE: "image",
    FILE: "file",
}

export const SORT_TYPES = {
    ASC: 1,
    DESC: -1,
}

export const SORT_KEYS = {
    ASC: 'ASC',
    DESC: 'DESC',
}

export const ALERT_TYPES = {
    SUCCESS: "success",
    ERROR: "error",
    DEFAULT: "default",
}

export const ALERT_POSITIONS = {
    CENTER: "center",
    RIGHT: "right",
    LEFT: "left",
}

export enum ICON_SIZE {
    TINY = 18,
    SMALL = 24,
    MEDIUM = 32,
    LARGE = 48,
}

export enum BADGE_STATUS {
    ACTIVE = "general.active",
    INACTIVE = "general.inactive",
}

export enum ELEMENT_SIZE {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export enum BUTTON_TYPE {
    BUTTON = "button",
    SUBMIT = "submit",
    RESET = "reset",
}

export enum BUTTON_STATUS {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    TERTIARY = "tertiary",
    OUTLINED = "outlined",
    DISABLED = "disabled",
    CHECKMAIL = "checkMail",
    PRIMARY_EFFECT = "primary -btnEffect",
    OUTLINED_EFFECT = "primary -btnEffect",
    SECONDARY_EFFECT = "secondary -btnEffect",
}

export enum INPUT_FILED_TYPE {
    TEXT = "text",
    PASSWORD = "password",
    // TODO:: Search is input type?
    SEARCH = "search",
    EMAIL = "email",
    FILE = "file",
    CHECKBOX = "checkbox",
    NUMBER = "number",
}

export enum ELEMENT_SHAPE {
    SQUARE = "-square",
    ROUNDED_SQUARE = "-roundedSquare",
    CIRCLE = "-circle",
}

export const ROLES = {
    ROLE_LOCATION_MANAGER: { id: 1, name: "ROLE_LOCATION_MANAGER" },
    ROLE_REGIONAL_MANAGER: { id: 2, name: "ROLE_REGIONAL_MANAGER" },
    ROLE_COMPANY_MANAGER: { id: 3, name: "ROLE_COMPANY_MANAGER" },
    ROLE_ADMIN: { id: 4, name: "ROLE_ADMIN" },
}

export enum REPORT_TYPES {
    DAILY_KEY = 1,
    WEEKLY_KEY = 2,
    MONTHLY_KEY = 3,
    YEARLY_KEY = 4,
    PERIODICAL_KEY = 5,
}

export enum CHART_TYPES {
    COLUMN = "column",
    PIE = "pie",
}

export enum TABLE_FILTER_TYPE {
    TEXT = "text",
    DATE_TIME = "date-time",
    SELECT = "select",
    CHECKBOX = "checkbox",
    REMOVE = "remove",
}

export const ACTIVE_INACTIVE_OPTIONS = (t) => [
    { id: 1, name: "Active" },
    { id: 0, name: "Inactive" },
]
