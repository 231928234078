import { Children, FC } from "react"

import AlertContextProvider from "./AlertContext"
import CurrentUserContextProvider from "./CurrentUserContext"
import TranslationContextProvider from "./TranslationContext"

import { IContextProps } from "utils/IContextProps"

const AppContext: FC<IContextProps> = ({ children }) => {
    return (
        <CurrentUserContextProvider>
            <TranslationContextProvider>
                <AlertContextProvider>
                    {Children.only(children)}
                </AlertContextProvider>
            </TranslationContextProvider>
        </CurrentUserContextProvider>
    )
}

export default AppContext
