export default {
    HOME: "/",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    COUNTRY_MANAGEMENT: "/country-management",
    USER_MANAGEMENT: "/user-management",
    CITY_MANAGEMENT: "/city-management",
    WORKING_TIME_MANAGEMENT: "/working-time-management",
    CREATE: "/create",
    EDIT: "/edit",
    USER_PROFILE: "/user-profile",
    REGION_MANAGEMENT: "/region-management",
    COMPANY_MANAGEMENT: "/company-management",
    LOCATION_MANAGEMENT: "/location-management",
    GATE_MANAGEMENT: "/gate-management",
    CAMERA_MANAGEMENT: "/camera-management",
    COMPARE_LOCATIONS: "/compare-locations",
    REPORTS: "/reports",
    DAILY: "/daily",
    WEEKLY: "/weekly",
    MONTHLY: "/monthly",
    PERIODICAL: "/periodical",
    YEARLY: "/yearly",
    MAILING_REPORTS: "/mailing-reports",
    TRANSLATION_MANAGEMENT: "/translation-management",
    VISIT_MANAGEMENT: "/visit-management",
    SYNC: "/sync_cameras_data",
    ADMIN_DASHBOARD: "/admin-dashboard",
}
