import { useTranslate } from "react-polyglot"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { ICON_SIZE } from "constants/enums"
import ROUTES from "constants/routes"

import { ISidebarItem } from "./ISidebar"
import { Icon } from "components/Icons"

export const SidebarItem = ({
    label,
    to,
    icon,
    active,
    setActive,
    subitems,
}: ISidebarItem & any) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const handleActive = () => {
        if (subitems) {
            setActive(active === to ? null : to)
        } else {
            setActive(to)
        }
    }

    const handleClick = (e: any, to: string) => {
        e.stopPropagation()
        e.preventDefault()
        navigate(to)
    }

    const getIsActive = () => {
        if (to !== ROUTES.HOME) return pathname.includes(to)
        return pathname === ROUTES.HOME
    }

    const isActive = getIsActive()

    return (
        <Link
            className={`m-sidenav__itemContainer ${
                active === to ? " -active" : ""
            }`}
            onClick={handleActive}
            to={subitems ? pathname : to}
        >
            <div className={`m-sidenav__item ${isActive ? " -active" : ""}`}>
                <Icon name={icon} color="#929BB3" size={ICON_SIZE.MEDIUM} />
                <span className="m-sidenav__itemLabel">{t(label)}</span>
                {subitems && <span className="m-sidenav__subItemsIcon"></span>}
            </div>

            {/* TODO: item type */}
            {subitems && (
                <div className="m-sidenav__subitems">
                    {subitems.map((item) => (
                        <div
                            className="m-sidenav__subitem"
                            key={item.itemKey}
                            onClick={(e) => handleClick(e, item.to)}
                        >
                            <Icon
                                name={icon}
                                color="#B4B9C4"
                                size={ICON_SIZE.SMALL}
                            />
                            <span className="m-sidenav__subitemLabel">
                                {t(item.label)}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </Link>
    )
}
