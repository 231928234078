import React, { FC, useContext, useEffect, useState } from "react"

import { CurrentUserContext } from "./CurrentUserContext"

import {
    editEntityService,
    getEntityByIdService,
} from "services/entity.service"
import { setUserLanguage } from "services/localStorage.service"

import { IContextProps } from "utils/IContextProps"

import ENTITY from "constants/entities"

const DEFAULT_LANGUAGE = "sr"

const DEFAULT_TRANSLATIONS = {
    locale: DEFAULT_LANGUAGE,
    messages: require(`../translations/${DEFAULT_LANGUAGE}.json`),
}

export const TranslationContext = React.createContext({
    translations: DEFAULT_TRANSLATIONS,
    setTranslations: (language) => {},
})

const TranslationContextProvider: FC<IContextProps> = ({ children }) => {
    const { currentUser, userId, setCurrentUser } =
        useContext(CurrentUserContext)

    const [translations, setTranslations] = useState(DEFAULT_TRANSLATIONS)

    useEffect(() => {
        if (currentUser) {
            setTranslations({
                locale: currentUser.language,
                messages: require(`../translations/${currentUser.language}.json`),
            })
        }
    }, [currentUser])

    const handleSetTranslations = async (language) => {
        try {
            if (currentUser) {
                await editEntityService(ENTITY.USER, userId, {
                    language: language.code,
                })
                const data = await getEntityByIdService(
                    ENTITY.USER,
                    currentUser.id,
                    { include: "role,avatar" }
                )
                setCurrentUser(data)
            } else {
                setTranslations({
                    locale: language.code,
                    messages: require(`../translations/${language.code}.json`),
                })
            }
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        setUserLanguage(translations.locale)
    }, [translations])

    return (
        <TranslationContext.Provider
            value={{
                translations,
                setTranslations: handleSetTranslations,
            }}
        >
            {children}
        </TranslationContext.Provider>
    )
}

export default TranslationContextProvider
