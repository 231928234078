import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslate } from "react-polyglot"

import { ICON_SIZE, MEDIUM_SCREEN_WIDTH, ROLES } from "constants/enums"
import ROUTES from "constants/routes"

import { ISidebar } from "./ISidebar"
import { SidebarItem } from "./SidebarItem"
import { Icon } from "../Icons"
import { CurrentUserContext } from "context/CurrentUserContext"

const Mailto = ({ email, subject, ...props }) => {
    return <a href={`mailto:${email}?subject=${subject}`}>{props.children}</a>
}

// TODO:: add Advanced Store Analytics into translations
export const Sidebar = ({
    openSidebar,
    setOpenSidebar,
    menuItems = [],
    width,
}: ISidebar) => {
    const t = useTranslate()

    const [active, setActive] = useState<string>(menuItems[0]?.itemKey)
    const { currentUser } = useContext(CurrentUserContext)

    const homeRoute =
        currentUser.role.name === ROLES.ROLE_ADMIN.name
            ? ROUTES.ADMIN_DASHBOARD
            : ROUTES.HOME

    return (
        <div
            className={`m-sidenav ${openSidebar ? "" : "-closed"}`}
            style={
                width <= MEDIUM_SCREEN_WIDTH && !openSidebar
                    ? { display: "none" }
                    : { display: "block" }
            }
        >
            <div
                className="m-sidenav__toggler"
                onClick={() => setOpenSidebar(!openSidebar)}
            />
            <div className="m-sidenav__logo">
                <div className="m-sidenav__logoBox">
                    <Link to={homeRoute}>
                        <div className="m-sidenav__logoBox--icon"></div>
                    </Link>
                    {openSidebar && (
                        <a href="">
                            {" "}
                            <h1 className="m-sidenav__logoBox--title">
                                Advanced <span>Store</span> Analytics
                            </h1>
                        </a>
                    )}
                </div>
            </div>

            <div className="m-sidenav__items">
                {menuItems.map((item, index) => {
                    return item?.divider ? (
                        <div key={index} className="m-sidenav__divider"></div>
                    ) : (
                        <SidebarItem
                            key={item.itemKey}
                            {...item}
                            active={active}
                            setActive={setActive}
                        />
                    )
                })}

                <div className="m-ctaEmail">
                    <Mailto email="asa@genesec.rs" subject="Sales help">
                        <Icon name="help" size={ICON_SIZE.MEDIUM} />
                        <span>{t("general.salesSupport")}</span>
                    </Mailto>
                    <Mailto email="asa@genesec.rs" subject="Technical help">
                        <Icon name="tech-support" size={ICON_SIZE.MEDIUM} />
                        <span>{t("general.techSupport")}</span>
                    </Mailto>
                </div>
            </div>
        </div>
    )
}
