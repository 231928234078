import { Fragment, useContext } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { CurrentUserContext } from "context/CurrentUserContext"

import loadable from "utils/loadable"

import ROUTES from "constants/routes"
import { ROLES } from "constants/enums"

import Layout from "components/Layout"

const Dashboard = loadable(() => import("screens/Dashboard"))
const AdminDashboard = loadable(() => import("screens/AdminDashboard"))
const CountriesManagement = loadable(
    () => import("screens/Admin/CountriesManagement")
)
const CompaniesManagement = loadable(
    () => import("screens/Admin/CompaniesManagement")
)
const CreateCompany = loadable(
    () => import("screens/Admin/CompaniesManagement/CreateCompany")
)
const EditCompany = loadable(
    () => import("screens/Admin/CompaniesManagement/EditCompany")
)
const UserManagement = loadable(() => import("screens/Admin/UsersManagement"))
const CreateUser = loadable(
    () => import("screens/Admin/UsersManagement/CreateUser")
)
const EditUser = loadable(
    () => import("screens/Admin/UsersManagement/EditUser")
)
const UserProfile = loadable(() => import("screens/User/UserProfile/index"))
const RegionsManagement = loadable(
    () => import("screens/Admin/RegionsManagement")
)
const CreateRegion = loadable(
    () => import("screens/Admin/RegionsManagement/CreateRegion")
)
const EditRegion = loadable(
    () => import("screens/Admin/RegionsManagement/EditRegion")
)
const CityManagement = loadable(() => import("screens/Admin/CitiesManagement"))
const CreateCity = loadable(
    () => import("screens/Admin/CitiesManagement/CreateCity")
)
const EditCity = loadable(
    () => import("screens/Admin/CitiesManagement/EditCity")
)
const WorkingTimesManagement = loadable(
    () => import("screens/Admin/WorkingTimesManagement")
)
const CreateWorkingTime = loadable(
    () => import("screens/Admin/WorkingTimesManagement/CreateWorkingTime")
)
const EditWorkingTime = loadable(
    () => import("screens/Admin/WorkingTimesManagement/EditWorkingTime")
)
const LocationsManagement = loadable(
    () => import("screens/Admin/LocationsManagement")
)
const CreateLocation = loadable(
    () => import("screens/Admin/LocationsManagement/CreateLocation")
)
const EditLocation = loadable(
    () => import("screens/Admin/LocationsManagement/EditLocation")
)
const GatesManagement = loadable(() => import("screens/Admin/GatesManagement"))
const CreateGate = loadable(
    () => import("screens/Admin/GatesManagement/CreateGate")
)
const EditGate = loadable(
    () => import("screens/Admin/GatesManagement/EditGate")
)
const CamerasManagement = loadable(
    () => import("screens/Admin/CamerasManagement")
)
const CreateCamera = loadable(
    () => import("screens/Admin/CamerasManagement/CreateCamera")
)
const EditCamera = loadable(
    () => import("screens/Admin/CamerasManagement/EditCamera")
)
const SyncCameras = loadable(
    () => import("screens/Admin/CamerasManagement/SyncCameras")
)
const MailingReportsManagement = loadable(
    () => import("screens/Admin/MailingReportsManagement")
)
const CreateMailingReport = loadable(
    () => import("screens/Admin/MailingReportsManagement/CreateMailingReport")
)
const EditMailingReport = loadable(
    () => import("screens/Admin/MailingReportsManagement/EditMailingReport")
)
const CompareLocations = loadable(() => import("screens/User/CompareLocations"))
const DailyReports = loadable(() => import("screens/User/Reports/DailyReports"))
const WeeklyReports = loadable(
    () => import("screens/User/Reports/WeeklyReports")
)
const MonthlyReports = loadable(
    () => import("screens/User/Reports/MonthlyReports")
)
const PeriodicalReports = loadable(
    () => import("screens/User/Reports/PeriodicalReports")
)
const YearlyReports = loadable(
    () => import("screens/User/Reports/YearlyReports")
)
const TranslateManagement = loadable(
    () => import("screens/Admin/TranslateManagement")
)
const CreateTranslate = loadable(
    () => import("screens/Admin/TranslateManagement/CreateTranslate")
)
const EditTranslate = loadable(
    () => import("screens/Admin/TranslateManagement/EditTranslate")
)
const VisitManagement = loadable(() => import("screens/Admin/VisitManagement"))
const EditVisit = loadable(
    () => import("screens/Admin/VisitManagement/EditVisit")
)
const CreateVisit = loadable(
    () => import("screens/Admin/VisitManagement/CreateVisit")
)

const {
    ROLE_ADMIN,
    ROLE_COMPANY_MANAGER,
    ROLE_REGIONAL_MANAGER,
    ROLE_LOCATION_MANAGER,
} = ROLES

const AuthRoutes = () => {
    const { currentUser } = useContext(CurrentUserContext)

    if (!currentUser) return null

    const renderAllowedRoutes = () => {
        switch (currentUser.role.name) {
            case ROLE_ADMIN.name:
                return (
                    <Fragment>
                        <Route>
                            <Route
                                path={`${ROUTES.ADMIN_DASHBOARD}`}
                                element={<AdminDashboard />}
                            />
                            <Route
                                path={`${ROUTES.COUNTRY_MANAGEMENT}`}
                                element={<CountriesManagement />}
                            />
                            <Route
                                path={ROUTES.USER_MANAGEMENT}
                                element={<UserManagement />}
                            />
                            <Route
                                path={`${ROUTES.USER_MANAGEMENT}${ROUTES.CREATE}`}
                                element={<CreateUser />}
                            />
                            <Route
                                path={`${ROUTES.USER_MANAGEMENT}${ROUTES.EDIT}/:id`}
                                element={<EditUser />}
                            />
                            <Route
                                path={`${ROUTES.COMPANY_MANAGEMENT}`}
                                element={<CompaniesManagement />}
                            />
                            <Route
                                path={`${ROUTES.COMPANY_MANAGEMENT}${ROUTES.CREATE}`}
                                element={<CreateCompany />}
                            />
                            <Route
                                path={`${ROUTES.COMPANY_MANAGEMENT}${ROUTES.EDIT}/:id`}
                                element={<EditCompany />}
                            />
                            <Route
                                path={ROUTES.CITY_MANAGEMENT}
                                element={<CityManagement />}
                            />
                            <Route
                                path={`${ROUTES.CITY_MANAGEMENT}${ROUTES.CREATE}`}
                                element={<CreateCity />}
                            />
                            <Route
                                path={`${ROUTES.CITY_MANAGEMENT}${ROUTES.EDIT}/:id`}
                                element={<EditCity />}
                            />
                            <Route
                                path={ROUTES.GATE_MANAGEMENT}
                                element={<GatesManagement />}
                            />
                            <Route
                                path={`${ROUTES.GATE_MANAGEMENT}${ROUTES.CREATE}`}
                                element={<CreateGate />}
                            />
                            <Route
                                path={`${ROUTES.GATE_MANAGEMENT}${ROUTES.EDIT}/:id`}
                                element={<EditGate />}
                            />
                            <Route
                                path={ROUTES.CAMERA_MANAGEMENT}
                                element={<CamerasManagement />}
                            />
                            <Route
                                path={`${ROUTES.CAMERA_MANAGEMENT}${ROUTES.CREATE}`}
                                element={<CreateCamera />}
                            />
                            <Route
                                path={`${ROUTES.CAMERA_MANAGEMENT}${ROUTES.EDIT}/:id`}
                                element={<EditCamera />}
                            />
                            <Route
                                path={`${ROUTES.CAMERA_MANAGEMENT}${ROUTES.SYNC}`}
                                element={<SyncCameras />}
                            />
                            <Route
                                path={ROUTES.TRANSLATION_MANAGEMENT}
                                element={<TranslateManagement />}
                            />
                            <Route
                                path={`${ROUTES.TRANSLATION_MANAGEMENT}${ROUTES.CREATE}`}
                                element={<CreateTranslate />}
                            />
                            <Route
                                path={`${ROUTES.TRANSLATION_MANAGEMENT}${ROUTES.EDIT}/:id`}
                                element={<EditTranslate />}
                            />
                            <Route
                                path={ROUTES.VISIT_MANAGEMENT}
                                element={<VisitManagement />}
                            />
                            <Route
                                path={`${ROUTES.VISIT_MANAGEMENT}${ROUTES.EDIT}/:id`}
                                element={<EditVisit />}
                            />
                            <Route
                                path={`${ROUTES.VISIT_MANAGEMENT}${ROUTES.CREATE}`}
                                element={<CreateVisit />}
                            />
                            {renderAdminAndCompanyAdminRoutes()}
                            {renderCommonRoutes()}
                        </Route>
                    </Fragment>
                )
            case ROLE_COMPANY_MANAGER.name:
                return (
                    <Fragment>
                        <Route path={ROUTES.HOME} element={<Dashboard />} />
                        {renderAdminAndCompanyAdminRoutes()}
                        {renderCommonRoutes()}
                    </Fragment>
                )
            case ROLE_REGIONAL_MANAGER.name:
                return (
                    <Fragment>
                        <Route path={ROUTES.HOME} element={<Dashboard />} />
                        <Route
                            path={ROUTES.COMPARE_LOCATIONS}
                            element={<CompareLocations />}
                        />
                        {renderCommonRoutes()}
                    </Fragment>
                )
            case ROLE_LOCATION_MANAGER.name:
            default:
                return (
                    <Fragment>
                        <Route path={ROUTES.HOME} element={<Dashboard />} />
                        {renderCommonRoutes()}
                    </Fragment>
                )
        }
    }

    const renderAdminAndCompanyAdminRoutes = () => {
        return (
            <Fragment>
                <Route
                    path={ROUTES.MAILING_REPORTS}
                    element={<MailingReportsManagement />}
                />
                <Route
                    path={`${ROUTES.MAILING_REPORTS}${ROUTES.CREATE}`}
                    element={<CreateMailingReport />}
                />
                <Route
                    path={`${ROUTES.MAILING_REPORTS}${ROUTES.EDIT}/:id`}
                    element={<EditMailingReport />}
                />
                <Route
                    path={ROUTES.REGION_MANAGEMENT}
                    element={<RegionsManagement />}
                />
                <Route
                    path={`${ROUTES.REGION_MANAGEMENT}${ROUTES.CREATE}`}
                    element={<CreateRegion />}
                />
                <Route
                    path={`${ROUTES.REGION_MANAGEMENT}${ROUTES.EDIT}/:id`}
                    element={<EditRegion />}
                />

                <Route
                    path={ROUTES.WORKING_TIME_MANAGEMENT}
                    element={<WorkingTimesManagement />}
                />
                <Route
                    path={`${ROUTES.WORKING_TIME_MANAGEMENT}${ROUTES.CREATE}`}
                    element={<CreateWorkingTime />}
                />
                <Route
                    path={`${ROUTES.WORKING_TIME_MANAGEMENT}${ROUTES.EDIT}/:id`}
                    element={<EditWorkingTime />}
                />
                <Route
                    path={ROUTES.LOCATION_MANAGEMENT}
                    element={<LocationsManagement />}
                />
                <Route
                    path={`${ROUTES.LOCATION_MANAGEMENT}${ROUTES.CREATE}`}
                    element={<CreateLocation />}
                />
                <Route
                    path={`${ROUTES.LOCATION_MANAGEMENT}${ROUTES.EDIT}/:id`}
                    element={<EditLocation />}
                />
                <Route
                    path={ROUTES.COMPARE_LOCATIONS}
                    element={<CompareLocations />}
                />
            </Fragment>
        )
    }

    const renderCommonRoutes = () => {
        return (
            <Fragment>
                <Route path={ROUTES.USER_PROFILE} element={<UserProfile />} />
                <Route
                    path={`${ROUTES.REPORTS}${ROUTES.DAILY}`}
                    element={<DailyReports />}
                />
                <Route
                    path={`${ROUTES.REPORTS}${ROUTES.WEEKLY}`}
                    element={<WeeklyReports />}
                />
                <Route
                    path={`${ROUTES.REPORTS}${ROUTES.MONTHLY}`}
                    element={<MonthlyReports />}
                />
                <Route
                    path={`${ROUTES.REPORTS}${ROUTES.PERIODICAL}`}
                    element={<PeriodicalReports />}
                />
                <Route
                    path={`${ROUTES.REPORTS}${ROUTES.YEARLY}`}
                    element={<YearlyReports />}
                />
            </Fragment>
        )
    }

    return (
        <Routes>
            <Route element={<Layout />}>
                {renderAllowedRoutes()}
                <Route
                    path="*"
                    element={
                        currentUser.role.name === ROLES.ROLE_ADMIN.name ? (
                            <Navigate to={ROUTES.ADMIN_DASHBOARD} />
                        ) : (
                            <Navigate to={ROUTES.HOME} />
                        )
                    }
                />
            </Route>
        </Routes>
    )
}

export default AuthRoutes
