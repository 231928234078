import { useState, useEffect, useContext } from "react"
import { AlertContext } from "context/AlertContext"

import axios, { BASE_URL, GET } from "utils/axiosClient"
import { formatResponseData, formatUrl } from "utils/jsonApiFormatters"
import { ALERT_TYPES } from "constants/enums"
import { IFetchDataById, Params } from "./ICustomHook"

const useFetchDataById = (
    entity: string,
    id: number,
    params: Params = {},
    reload: boolean = false,
    apiUrl: string = BASE_URL
): IFetchDataById => {
    const { setAlert } = useContext(AlertContext) || {}

    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                let PATH = `/${entity}/${id}`
                const url = formatUrl(PATH, params)
                const response = await axios(GET, url, null, true, apiUrl)
                const { data } = formatResponseData(response.data)
                setData(data)
                setIsLoading(false)
                setError(null)
            } catch (error: any) {
                setData(null)
                setIsLoading(false)
                setError(error)
                if (error?.response?.status === 403) {
                    setAlert(error, ALERT_TYPES.ERROR)
                }
            }
        }
        if (id) {
            getData()
        }
    }, [id, reload])

    return {
        data,
        isLoading,
        error,
    }
}

export default useFetchDataById
