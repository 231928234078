import * as React from "react"
import { IAvatar } from "./IAvatar"
import { Icon } from "components/Icons"
import { ICON_SIZE } from "constants/enums"

export function Avatar({
    loading,
    firstName,
    lastName,
    outline,
    avatarPath,
    role,
    size,
    ...props
}: IAvatar) {
    return (
        <>
            <div
                className={`a-avatarBox__img ${
                    outline ? "-outline" : ""
                } -${size}`}
            >
                {avatarPath ? (
                    <img
                        className="a-avatarImage"
                        src={avatarPath}
                        alt={firstName}
                    />
                ) : (
                    <Icon size={ICON_SIZE.MEDIUM} name="noUser" />
                )}
            </div>
            <div className="a-avatarBox__user">
                <span className={`a-avatarBox__user__name -username`}>
                    {firstName} {lastName}
                </span>
            </div>
        </>
    )
}
